// import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
import DiagnosisReport from "views/report-manager/diagnosisReport";
import GapAnalysis from "views/report-manager/gapAnalysis";
import RoadMap from "views/report-manager/roadmap";
import Others from "views/report-manager/others";
import Login from "views/report-manager/login";
import SignIn from "views/auth/SignIn";
import Profiles from "views/report-manager/profiles";
// Icon Imports
import {
  MdAnalytics,
  MdMonitorHeart,
  MdMore,
  MdHome,
  MdAccountCircle
} from "react-icons/md";

import { FaRoad } from "react-icons/fa";

const routes = [
  {
    name: "Default",
    layout: "/auth",
    path: "auth",
    icon: <MdHome className="h-6 w-6" />,
    component: <Login />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <MdHome className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Diganosis Report",
    layout: "/report-manager",
    path: "diagnosis-report",
    icon: <MdMonitorHeart className="h-6 w-6" />,
    component: <DiagnosisReport />,
  },
  {
    name: "GAP Analysis",
    layout: "/report-manager",
    icon: <MdAnalytics className="h-6 w-6" />,
    path: "gap-analysis",
    component: <GapAnalysis />,
  },
  {
    name: "Roadmap",
    layout: "/report-manager",
    path: "roadmap",
    icon: <FaRoad className="h-6 w-6" />,
    component: <RoadMap />,
  },  
  {
    name: "Others",
    layout: "/report-manager",
    path: "others",
    icon: <MdMore className="h-6 w-6" />,
    component: <Others />,
  },  
  {
    name: "Profiles",
    layout: "/report-manager",
    path: "profiles",
    icon: <MdAccountCircle className="h-6 w-6" />,
    component: <Profiles />,
  },  
];
export default routes;
