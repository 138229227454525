export type GapAnalysisOptionColumns = {
  isDisplay?: 'true' | 'false';
	businessPurpose?: Array<string>;
	purposeType?: Array<string>;
	currentState?: Array<string>;
	desiredState?: Array<string>;
	gapIdentified?: Array<string>;
	gapDescription?: Array<string>;
	correctiveAction?: Array<string>;
	priority?: Array<string>;
};


export type GapAnalysisInputs = {
  isDisplay?: string;
	businessPurpose?: string;
	purposeType?: string;
	currentState?: string;
	desiredState?: string;
	gapIdentified?: string;
	gapDescription?: string;
	correctiveAction?: string;
	priority?: string;
};

export const BUSINESS_PURPOSE = {
	"ORDER_MANAGEMENT": {
    text: "Order Management",
    key: "orderManagementOptions",
  },
	"MARKETING": {
    text: "Marketing",
    key: "marketingOptions",
  },
	"SALES_PROCESSING_AND_METHODS": {
    text: "Sales Processing and Methods",
    key: "salesProcessingAndMethodsOptions",
  },
	"PROJECT_MANAGEMENT": {
    text: "Project Management",
    key: "projectManagementOptions",
  },
  "VENDOR_MANAGEMENT_AND_QUALIFICATION": {
    text: "Vendor Management & Qualification",
    key: "vendorManagementAndQualification",
  },
}

export const GapAnalysisHeader: Array<{id: keyof GapAnalysisOptionColumns, header: string}> = [
  {
		id: "isDisplay",
		header: "",
	},
	{
		id: "businessPurpose",
		header: "Business Purpose",
	},
	{
		id: "purposeType",
		header: "Purpose Type",
	},
	{
		id: "currentState",
		header: "Current State",
	},
	{
		id: "desiredState",
		header: "Desired State",
	},
	{
		id: "gapIdentified",
		header: "GAP Identified",
	},
	{
		id: "gapDescription",
		header: "GAP Description",
	},
	{
		id: "correctiveAction",
		header: "Corrective Action",
	},
	{
		id: "priority",
		header: "Priority",
	},
];

export const gapAnalysisOrder = [
  "isDisplay",
	"businessPurpose",
	"purposeType",
	"currentState",
	"desiredState",
	"gapIdentified",
	"gapDescription",
	"correctiveAction",
	"priority",
];


export const marketingOptions: Array<GapAnalysisOptionColumns> = [
	{
    isDisplay: 'true',
		businessPurpose: [
      "Marketing Strategy",
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "The current market strategy includes a limited yet consistent effort to educate new customers about knowledge and the need for insurance, both as a necessity and a successful investment product.", "There is currently no marketing strategy."
    ],
		desiredState: [
      "To leverage the company's knowledge and experience in insurance and investments and create marketing solutions to reach prospective customers, to increase revenue."
    ],
		gapIdentified: [
      "Good use of social media channels and consistent posting. Not enough to reflect the importance of sufficient coverage of insurance and investments.", 
      "Limited use of social media channels on Linkedin, Facebook, Instagram, TikTok and YouTube as popular social media platforms."
    ],
		gapDescription: [
      "Demographics for people aged 18+ could be targeted better with the right social platforms. Increased participation in effective social media channels is required and is currently missing.", 
      "Demographics for business owners aged 30+ have not been targeted with the right social platforms. Increased participation in effective social media channels is required and is currently missing.", 
      "Demographics for people aged 25+ have not been targeted with the right social platforms. Increased participation in effective social media channels is required and is currently missing."
    ],
		correctiveAction: [
      "Increase participation in the appropriate social media channels will result in quality engagement and increased revenues.", 
      "Increase participation in the appropriate social media channels will result in quality awareness and revenue opportunities."
    ],
		priority: ['1', '2', '3'],
	},
	{
    isDisplay: 'true',
		businessPurpose: [
      "Marketing Process",
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "No step-by-step process defined owners for specific tasks of the marketing process.",
      "No step-by-step process defined. No designated employee resource to define and execute the marketing process. This puts too much responsibility on the owner with the marketing process.",
    ],
		desiredState: [
      "A well-defined step-by-step marketing process that is well documented, easy to understand, and onboard new marketing personnel. The marketing process should closely align with the KPIs, organizational, and sales objectives to ensure revenue growth.",
    ],
		gapIdentified: [
      "No marketing messaging from customers, employees, and the community at large.",
    ],
		gapDescription: [
      "Marketing messaging from customers, employees, and the community is missing from the marketing process. This engagement is important to build a comprehensive marketing message plan for the company.",
    ],
		correctiveAction: [
      "Regular and periodic engagement of customers, employees, and the community and publishing these engagements on social media.", 
      "Regular and periodic engagement of customers, employees, and the community and publishing these engagements on social media. Build refreshing and engaging marketing campaigns and monitor engagement and success metrics."
    ],
		priority: ['1', '2', '3'],
	},
	{
    isDisplay: 'true',
		businessPurpose: [
      "Digital Social Marketing roadmap",
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "Little-to-no visibility/followers on digital social channels.",
      "Inactive social media accounts. Little-to-no visibility/followers on digital social channels that are Facebook.",
    ],
		desiredState: [
      "Active social media accounts to target a large section of customers.  Honest and prompt participation in digital social engagements that help patrons engage with the company's digital social content.",
      "Inactive social media accounts to target a large section of customers. Honest and prompt participation in digital social engagements that help patrons engage with the company's digital social content.",
      "Active social media accounts target a large section of customers. Honest and prompt participation in digital social engagements that help patrons engage with the company's digital social content, emphasizing the security and peace of mind the company brings to its customers.",
    ],
		gapIdentified: [
      "Only some social media channels are being used, missing out on popular channels such as YouTube, Facebook.",
      "Little-to-no social media channels are being used, missing out on popular channels such as LinkedIn, YouTube, Facebook, Instagram, Tiktok.",
    ],
		gapDescription: [
      "Facebook & YouTube cater to the right demographics, resulting in a suitable reach of the company's offerings.",
      "Linkedin, Facebook, Instagram, Tiktok, and YouTube cater to the right demographics, resulting in a suitable reach of the company's offerings.",
    ],
		correctiveAction: [
      "To bring about earnest participation in building digital social followings. Also to ensure relationship building amongst patrons and existing customers alike.",
      "To bring about earnest participation in building confidence with businesses, to position the company as a thought leader through digital content marketing. Also, to ensure relationship building amongst patrons and existing customers alike.",
      "To bring about earnest participation in building confidence with businesses, to position the company as a thought leader through digital content marketing. Also, to ensure relationship building amongst patrons and existing customers for a sustained subscription of the company's services.            ",
    ],
		priority: ['1', '2', '3'],
	},
  {
    isDisplay: 'true',
		businessPurpose: [
      "Content Leadership",
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "No actual content plan. The company does not focus on content creation.",
    ],
		desiredState: [
      "Build valuable content to engage followers on social media."
    ],
		gapIdentified: [
      "No content or plan for advertisements or marketing.",
    ],
		gapDescription: [
      "Lack of quality content in pictures, videos, blogs, infographics, and even memes can be useful in establishing authority over specific subjects. The company has a gap in qualified people and creatives that can enable high-quality social engagement.",
    ],
		correctiveAction: [
      "The company must leverage its experience in teaching about its products and use that experience to educate the community about the value its products bring. The company must adopt a diverse content strategy to continue to engage all its patrons across the different facets of social media communication. They must choose content platforms such as YouTube, and Facebook to engage with the right target audience.",
    ],
		priority: ['1', '2', '3'],
	},
	{
    isDisplay: 'true',
		businessPurpose: ["Stakeholder Participation in Marketing Communication", "Community Participation in Marketing Communication"],
		purposeType: ["Core", "Auxiliary"],
		currentState: ["The company currently fails to engage customers, employees, and the community in building a strong, consistent marketing communication plan."],
		desiredState: ["To engage all key stakeholders in building a comprehensive marketing communication plan."],
		gapIdentified: ["No engagement with employees, customers, and the community to bring out their feelings and experiences of the company."],
		gapDescription: ["The company must focus on gathering testimonials and feedback from employees, customers, and the community at regular intervals and must publish this content for the community to experience."],
		correctiveAction: ["Follow the OneHub proposed digital marketing content plan and create a comprehensive marketing communication plan for better visibility.  "],
		priority: ["1", "2", "3"],
	},
	{
    isDisplay: 'true',
		businessPurpose: ["Structured Marketing Collaterals"],
		purposeType: ["Core", "Auxiliary"],
		currentState: [
      "The company currently fails to document and preserve appreciation, endorsements, and feedback.",
      "The company currently fails to document and preserve external collaterals, such as appreciation, endorsements, feedback, and internal collaterals, such as opportunities and partnerships."
    ],
		desiredState: [
      "Document and publish important testimonials and feedback by creating marketing collaterals from endorsements.",
      "Document and publish important testimonials and feedback by creating marketing collaterals from endorsements. Build opportunity presentations that indicate the value of engaging with the company as a business partner. "
    ],
		gapIdentified: ["Needs to have testimonials documented and created in the right marketing collaterals."],
		gapDescription: ["The company does not have the right marketing collaterals, which implies wasted opportunities in showcasing the trust they have earned from customers."],
		correctiveAction: [
      "The company does not have the right marketing collaterals, which implies wasted opportunities in showcasing the trust they have earned from customers.",
      "The company must focus on gathering testimonials and feedback from customer endorsements and build quality marketing collaterals they can showcase to customers at events and digital platforms."
    ],
		priority: ["1", "2", "3"],
	},
];

export const orderManagementOptions: Array<GapAnalysisOptionColumns> = [
	{
    isDisplay: 'true',
		businessPurpose: ["Qualification & Underwriting"],
		purposeType: ["Core"],
		currentState: ["Highly effective and process-driven client qualification and underwriting."],
		desiredState: ["Improvise current qualification and underwriting methods by introducing autonomous software-based decision-making processes."],
		gapIdentified: ["Lack of technology and IT solutions leads to monotonous underwriting and qualification paperwork."],
		gapDescription: ["The company uses old-school paper-based information gathering and evaluation methods to evaluate a fit for insurance and investment. It also uses the clients' opinions of their health and qualification for a given insurance proposal."],
		correctiveAction: ["Gather customer data on secure platforms and process this information electronically (quickly and accurately). Also, evaluate potential customer information in real-time to identify, qualify and evaluate opportunities to upgrade services.  "],
		priority: ["1", "2", "3"],
	},  
  {
    isDisplay: 'true',
    businessPurpose: ["Repeat Customers"],
    purposeType: ["Core", "Auxiliary"],
    currentState: ["Despite large volumes of repeat customers (90%), the company does not have active sales pursuing."],
    desiredState: ["The company must have a healthy mix of new and repeat customers. The company must have a healthy forecast of new customer pipeline and predictable awareness of repeat orders from existing customers."],
    gapIdentified: ["No IT or business processes."],
    gapDescription: ["Determine the need for a stronghold in customer engagement and retention practices. This causes a myopic revenue vision resulting in a reduced ability to take the business risk."],
    correctiveAction: ["Use systems and processes to identify the right software to engage and record repeat customer sales."],
    priority: ['1', '2', '3']
  }
]

export const salesProcessingAndMethodsOptions: Array<GapAnalysisOptionColumns> = [
	{
    isDisplay: 'true',
		businessPurpose: [
      "Sales Stategy",
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "Single-founder operation. The systems and methods lack external motivation to generate new business.",
      "There is no dedicated personnel for sales.",
      "Despite dedicated sales personnel, sales continue to be a unidimensional effort focusing on a red ocean market.",
    ],
		desiredState: [
      "The company must have high-value investors and a constant, predictable pipeline of new incoming customers.",
      "The company must have high-value customers for repeat orders and a constant, predictable pipeline of new incoming customers. Must have better forecasting of revenues.",
      "The company must have high-value customers for repeat orders and a constant, predictable pipeline of new incoming customers. Must have better forecasting of revenues.",
    ],
		gapIdentified: [
      "Disjointed effort in B2C sales, lacks strategy and planning to improve outreach to end-customers.",
      "Disjointed effort in B2B sales, lacks strategy and planning to focus on new sales opportunities.",
      "No focus on new sales opportunities.",
    ],
		gapDescription: [
      "No dedicated sales-person to approach new B2B or B2C opportunities.",
      "No dedicated sales-person to approach new B2B opportunities.",
      "Lack of motivated sales effort to explore blue ocean opportunities that result in the risk of irrelevance in a hyper-competitive market.",
    ],
		correctiveAction: [
      "Adopt digital solutions, and the appropriate software, such as CRM, to leverage B2B and B2C sales. Accommodate resources for longer buying cycles.",
      "Adopt digital solutions, and the appropriate software such as CRM, to leverage B2B sales.",
      "Adopt digital solutions and the appropriate software, such as CRM, to leverage B2B sales. Explore an opportunistic fit with two key markets: 1) New B2B customers, 2) Retail subscription to B2C customers.",
    ],
		priority: ['1', '2', '3'],
	},  
	{
    isDisplay: 'true',
		businessPurpose: [
      "Sales Pipeline",
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "The company's owner has built a successful, time-tested process to build a sales pipeline. Although effective, this is a manual, tedious, and linear scale method.",
      "When inquiries are made, the sales effort and new leads are identified from the brick-and-mortar store.",
    ],
		desiredState: [
      "Building a sales pipeline and generating qualified leads must be an automated, scalable, and low-touch technology process.",
      "To gather more sales leads and convert them into revenues.",
      "To gather more sales leads electronically, in a path of least resistance and then convert them into revenues.",
    ],
		gapIdentified: [
      "Lack of IT tools and standard operating procedures (SOP) limits the company's ability to automate leads into a sales pipeline.",
      "A non-existent sales team results in missed revenue opportunities.",
      "A sales team that has a myopic view of a market niche results in missed revenue opportunities.",
    ],
		gapDescription: [
      "As a combination, software, additional sale resources, technology, and SOPs can disrupt the current linear lead generation process.",
      "The company has not hired sales personnel to reach new sales opportunities.",
      "The company has not combined sales resources and software solutions to explore new market opportunities.",
    ],
		correctiveAction: [
      "Implement a sales strategy that enables identifying and prioritizing multiple sales channels. Focus on honing short-medium-long term sales pipelines that provision for predictable revenues. Digitize data points to ensure prompt follow-ups and need analysis & fulfillment that results in high efficiency.",
      "Currently focusing on B2B, but for additional focus on B2C sales, the company must implement a sales strategy that identifies and prioritizes multiple sales channels. Focus on honing short-medium-long term sales pipelines that provision for predictable revenues. Digitize data points to ensure prompt follow-ups and need analysis & fulfillment that results in high efficiency.",
    ],
		priority: ['1', '2', '3'],
	},
  {
    isDisplay: 'true',
		businessPurpose: [
      "Sales Targets"
    ],
		purposeType: [
      "Core", 
      "Auxiliary"
    ],
		currentState: [
      "Currently no defined sales targets.",
      "Not enough impetus on structured KPIs and defined sales targets.",
    ],
		desiredState: [
      "Key account personnel must have defined sales targets.",
    ],
		gapIdentified: [
      "Lack of defined sales targets and personnel results in missed opportunities for certain revenue streams."
    ],
		gapDescription: [
      "Consistent and growing revenues to sustain the business is necessary to achieve the long-term vision.",
      "Consistent litmus testing of new market opportunities is important to grow revenues and sustain profitable growth.",
    ],
		correctiveAction: [
      "Implement sales strategies to drive revenue, maintain the business, and grow the company.",
      "Implement sales strategies, use defining software to drive revenue, maintain the business, and grow the company.",
    ],
		priority: ['1', '2', '3'],
	},  
]

export const projectManagementOptions: Array<GapAnalysisOptionColumns> = [
  {
    isDisplay: 'true',
		businessPurpose: [ "Project Management" ],
		purposeType: [ "Core", "Auxiliary" ],
		currentState: [ "Currently, Project Management activity is a combination of spreadsheets and the owner personally checking in on the progress of all active projects." ],
		desiredState: [ "An Automated, checklist-based list and Standard Operating Procedure (SOPs) method that helps monitor progress across multiple projects with a dashboard interface." ],
		gapIdentified: [ "No knowledge of tools, methods and processes. Not using Specific Project Management Software." ],
		gapDescription: [ "Lack of knowledge of systems and IT tools keeps the company from engaging all employees in a time-bound delivery schedule. The owner cannot ensure accountability, speed and accuracy without having to intervene to check for the same personally." ],
		correctiveAction: [ "Implement a Simple to Medium level Project Management Software and educate the owner to use the same. Also, ensure adoption by engaging with all employees and asking them to update workflows. Finally, ensure this information is available in a consolidated manner to the firm's owner." ],
		priority: ['1', '2', '3'],
	}, 
]

export const vendorManagementAndQualification: Array<GapAnalysisOptionColumns> = [
  {
    isDisplay: 'true',
		businessPurpose: [ "Vendor Consolidation & Qualification" ],
		purposeType: [ "Auxiliary", "Core",  ],
		currentState: [ "Currently has over many vendors but has no system to manage those vendors. The company uses excel to manage vendor relationships." ],
		desiredState: [ "A system should be integrated to qualify and consolidate vendors." ],
		gapIdentified: [ "No system is implemented to manage vendors." ],
		gapDescription: [ "Managing many vendors is a lot of administrative activity for 12120933 Canada Inc. Ideally, having a limited set of vendors who are well-qualified and capable of supplying everything 12120933 Canada Inc needs is sufficient." ],
		correctiveAction: [ "Choose the most important and profitable SKUs and identify the best vendors to deliver those goods. Periodically review the vendor list for quality, accuracy, and cost. " ],
		priority: ['1', '2', '3'],

	}, 
]
