import { useMemo, useRef } from "react";
import { RoadmapColorChart, RoadmapKeyOrder } from "../variables/roadmapData";
import { RoadMapTableProps, RoadmapHeader } from "../variables/dataType";

const RoadmapTable = (props: RoadMapTableProps) => {

  const { headers, rows, handleUpdateState } = props
  const roadmapStepsRefs = useRef<HTMLParagraphElement[]>([]); 
  
  const roadmapHeaders = useMemo(() => {
    const getHeaderClassNames = (head: RoadmapHeader, index: number) => {
      let classNames = ['text-white', 'text-sm', 'bg-primary'];
      switch(head.id) {
        case 'isDisplay':
          classNames.push('w-2');
          break;
        case 'name':
          classNames.push('w-80');
          break;
        default:  
          classNames.push('w-10');
      }
    
      if(index === 0) {
        classNames.push('rounded-tl-lg', 'pr-1', 'pl-4');
      } else if(index === headers.length - 1) {
        classNames.push('rounded-tr-lg', 'pl-1', 'pr-4');
      } else {
        classNames.push('px-1');
      }
    
      return classNames.join(' ');
    }

    return headers.map((head, index) => {
      const classNames = getHeaderClassNames(head, index);
  
      return (
        <th className={classNames} key={index}>
          <span className="flex justify-center">{head.header}</span>  
        </th>
      );
    });
  }, [headers ]);

  const roadmapRows = useMemo(() => {
    const handleCheckboxChange = (
      rowIndex: number,
      header: string,
      event: React.ChangeEvent<HTMLInputElement>) => {
      handleUpdateState(rowIndex, header, event.target.checked);
    };
    
    const handleTableOnClick = (
      rowIndex: number,
      header: string,
      value: boolean | string) => {
      handleUpdateState(rowIndex, header, !value);
    };

    const handleRoadmapStepInput = (rowIndex: number, header: string) => {
      if (handleUpdateState) {
        const updatedValue = roadmapStepsRefs.current[rowIndex].innerHTML;
        handleUpdateState(rowIndex, header, updatedValue)
      }
    };
    return rows.map((row, rowIndex) => (
      <tr key={rowIndex} className="">
        {
          Object.entries(row)
          .sort((a, b) => {
            const aIndex = RoadmapKeyOrder.indexOf(a[0]); 
            const bIndex = RoadmapKeyOrder.indexOf(b[0]);
            return aIndex - bIndex;  
          })
          .map(([key, value], colIndex) => {
            const matchingHeader = headers.find(header => header.id === key);
            switch(matchingHeader?.id) {
              case 'isDisplay':
                return (
                  <td key={`${rowIndex}-${key}`}>
                    <span className="flex justify-center">
                      <input 
                        type="checkbox"
                        checked={value === true} 
                        className="checkbox checkbox-secondary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(rowIndex, matchingHeader.id, event)}
                      />
                    </span>
                  </td>
                );

              case 'steps':
                return ( 
                  <td key={`${rowIndex}-${key}`} className="w-80">
                      <p
                        key={`text-${rowIndex}-${key}`}
                        className="text-lg outline-0 dark:text-white"
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                        ref={el => roadmapStepsRefs.current[rowIndex] = el}  
                        onBlur={() => handleRoadmapStepInput(rowIndex, matchingHeader.id)}
                      >
                        {value}
                      </p>
                  </td>
                );

              default:
                const classNames = ['h-4'];
                const tdClassNames = ['p-0', 'w-10'];
                if (colIndex === headers.length - 1) {
                  classNames.push('mr-4');
                }
                if (matchingHeader.id !== 'weekends') {
                  tdClassNames.push('hover:bg-lightPrimary', 'dark:hover:bg-navy-800');
                }
                
                const getColorByHeaderId = (headerId: string): string => {
                  return RoadmapColorChart[headerId as keyof typeof RoadmapColorChart] || '';
                };
                
                const cellKey = `${rowIndex}-${key}`;
                const cellColorClass = getColorByHeaderId(matchingHeader.id);
                const cellContentClass = value && cellColorClass ? cellColorClass + ' ' + classNames.join(' ') : classNames.join(' ');
                
                return (
                  <td className={tdClassNames.join(' ')} key={cellKey} onClick={() => handleTableOnClick(rowIndex, matchingHeader.id, value)}>
                    <div className={cellContentClass}></div>
                  </td>
                );
            }
          })
        }
        
      </tr>
    ));
  }, [rows, headers, handleUpdateState]);  

  


  return (
    <div className="overflow-x-auto">
      <table className="table bg-white dark:bg-navy-700">
        <thead className="color: white dark:bg-navy-700">
          <tr className="bg-primary rounded-t-lg" key="body">{roadmapHeaders}</tr>
        </thead>
        <tbody>
          {roadmapRows}
        </tbody>
      </table>
    </div>
  );
};

export default RoadmapTable;
