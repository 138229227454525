import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { GET_PROFILES } from "data/apiUrls";
import { fetchData } from "services/apiServices";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn) {
      setIsLoggedIn(JSON.parse(storedIsLoggedIn));
    }
    handleGetProfiles();
  }, []);

  const handleGetProfiles = async () => {
    try {
      const response = await fetchData(GET_PROFILES);
      if (Array.isArray(response)) {
        localStorage.setItem('profiles', JSON.stringify(response));
        return response;
      } else {
        localStorage.setItem('profiles', '[]');
        return [];
      }
    } catch (error) {
      // Handle the error here or rethrow it to propagate to the caller
      console.error('Error:', error);
      throw error;
    }
  };

  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  //   localStorage.setItem("isLoggedIn", JSON.stringify(true));
  // };

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  //   localStorage.removeItem("isLoggedIn");
  // };

  const isUserLoggedIn = isLoggedIn;

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      {isUserLoggedIn ? (
        <Route path="report-manager/*" element={<AdminLayout />} />
      ) : (
        <Route path="report-manager/*" element={<Navigate to="/" replace />} />
      )}
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route
        path="/*"
        element={
          isUserLoggedIn ? (
            <Navigate to="/report-manager/diagnosis-report" replace />
          ) : (
            <Navigate to="/auth" replace />
          )
        }
      />
    </Routes>
  );
};

export default App;