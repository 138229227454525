import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { useRef, useState } from 'react';
import DiagnosisForm from "./components/DiagnosisForm";
import LegendChart from "./components/LegendChart"
import { MdFileDownload } from "react-icons/md";
import OnehubDropdown from 'components/onehubDropdown';

enum DiagnosisCategories {
  DIAGNOSIS_FORM ='Diagnosis Form',
  DIAGNOSIS_CHART ='Diagnosis Chart',
}
const DiagnosisReport = () => {
  const chartRef = useRef(null);
  const [ diagnosisCategory,  setDiagnosisCategory] = useState('Diagnosis Form')
  const options = ['Diagnosis Form', 'Diagnosis Chart',]
  const handleDownload = async (ref: React.MutableRefObject<any>) => {
    try {
      const form = ref.current;
      const canvas = await html2canvas(form);
      canvas.toBlob(function (blob) {
        saveAs(blob, `${diagnosisCategory}.png`);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='mt-5 grid h-full grid-cols-1 gap-5 mb-10'>
        <OnehubDropdown
          showBorder={true}
          handleUpdateState={(selectedValue: string) => setDiagnosisCategory(selectedValue)}
          placeholder="Core Improvement Opportunities"
          value={diagnosisCategory}
          options={options}
        ></OnehubDropdown>
        { diagnosisCategory === DiagnosisCategories.DIAGNOSIS_FORM &&
              <DiagnosisForm 
                handleDownload={handleDownload}
              />
        }
        { diagnosisCategory === DiagnosisCategories.DIAGNOSIS_CHART &&
          <div className='max-w-[400px] items-end mt-4 flex flex-col gap-y-4 mb-10'>
            <div ref={chartRef} className='w-full bg-lightPrimary dark:bg-navy-900 p-4'>
              <LegendChart />
            </div>
            <button className='btn btn-primary rounded-full w-32 shadow-xl' onClick={() => handleDownload(chartRef)}> <MdFileDownload /> </button>
          </div>
        }
      </div>
    </>    
  );
};

export default DiagnosisReport;
