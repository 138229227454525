import React, { useEffect } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [profiles, setProfiles] = React.useState([]);

  useEffect(() => {
    setProfiles(getProfilesFromLocalStorage());
  }, []);

  const getProfilesFromLocalStorage = () => {
    const profilesJSON = localStorage.getItem('profiles');
    if (profilesJSON) {
      try {
        const profiles = JSON.parse(profilesJSON);
        return profiles;
      } catch (error) {
        console.error('Error parsing profiles from localStorage:', error);
      }
    }
    return [];
  }

  const handleDefaultProfileChange = (    
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { value } = event.target;
    console.log(`value: ${value}`)
    const index = profiles.indexOf(value);
    if (index !== -1) {
      profiles.splice(index, 1);
      profiles.unshift(value);
      localStorage.setItem('profiles', JSON.stringify(profiles));
    }
    window.location.reload();
  }
  const ProfileSelectList = ({profiles: []}) => {
    return (
      <select 
        className="select select-xs w-full max-w-xs"
        onChange={(event) => handleDefaultProfileChange(event)}
      >
        {profiles.map((profile, index) => (
          <option key={index} value={profile}>
            {profile}
          </option>
        ))}
      </select>
    );
  }  

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">

        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2">
        <div>
          <ProfileSelectList profiles={profiles} />
        </div>        
        
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
