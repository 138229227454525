const Footer = () => {
  return (
    <div className="sticky bottom-0 flex w-full flex-col items-center p-4 justify-between lg:px-8 xl:flex-row">
      <h5 className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        <p className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
          © OneHub Business Consulting.
        </p>
      </h5>
    </div>
  );
};

export default Footer;
