import {
  roadmapWeeksHeaders,
  roadmapDaysHeaders,
  ROADMAP_CATEGORIES,
} from "./variables/roadmapData"

import { v4 as uuidv4 } from 'uuid';
import { MdFileDownload, MdSave } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import saveAs from "file-saver";
import OnehubDropdown from "components/onehubDropdown";
import RoadmapTable from "./components/roadmapTable";
import { RoadmapHeader, RoadmapRow } from "./variables/dataType";
import { postData } from "services/apiServices";
import { GET_DEFAULT_BY_KEY, UPDATE_DEFAULT_BY_KEY } from "data/apiUrls";

const Roadmap = () => {
  const populateRoadmap = (rows: RoadmapRow[], headers: RoadmapHeader[]) => {

    const populatedRows = rows.map(row => ({...row}));
    
    headers.forEach(header => {
      // leave steps and isDisplay unchanged 
      if(header.id !== 'steps' && header.id !== 'isDisplay') {
        populatedRows.forEach((row: any) => {
          if(!row[header.id]) {
            row[header.id] = false;
          }  
        });
      }
    });
    return populatedRows;
  
  };

  const tableRef = useRef(null);
  const [tableHeaderState, setTableHeaderState] = useState(roadmapWeeksHeaders);
  // const [tableDataState, setTableDataState] = useState(populateRoadmap(salesRoadmap, roadmapWeeksHeaders));
  const [tableDataState, setTableDataState] = useState([]);
  const [categoryState, setCategoryState] = useState(ROADMAP_CATEGORIES.SALES);
  const [loadingState, setLoadingState] = useState(false);
  const profile = JSON.parse(localStorage.getItem("profiles"))[0];
  useEffect(() => {
    handleCategoryUpdateState(ROADMAP_CATEGORIES.SALES.text)
  }, []);

  const getRoadMapDefaultByKey = async (key: string) => {
    try {
      setLoadingState(true);
      const response = await postData(GET_DEFAULT_BY_KEY, { key: key, profile: profile })
      return response[0][key];
    } catch (error) {
      alert('Connection Error');
      console.error(error);
    } finally {
      setLoadingState(false);
    }   
  }

  const handleCategoryUpdateState = async (category: string) => {
    try{
      const selectedCategory = Object.values(ROADMAP_CATEGORIES).find(o => o.text === category);
      setCategoryState(selectedCategory)
      const roadmapDefault = await getRoadMapDefaultByKey(selectedCategory.key)
    
      switch(category){
        case ROADMAP_CATEGORIES.MARKETING.text:
        case ROADMAP_CATEGORIES.SALES.text:
          setTableHeaderState(roadmapWeeksHeaders);
          setTableDataState(populateRoadmap(roadmapDefault, roadmapWeeksHeaders));
          break;
        case ROADMAP_CATEGORIES.WEEK_3.text:
          setTableHeaderState(roadmapDaysHeaders);
          setTableDataState(populateRoadmap(roadmapDefault, roadmapDaysHeaders));
          break;
      }
    } catch(error) {
      console.error(JSON.stringify(error))
    }
  };

  const saveRoadmap = async () => {
    const requestBody = {
      // todo: update to use uuid
      partitionKey: uuidv4(),
      type: categoryState.key,
      templateKey: 'new template key',
      [categoryState.key]: tableDataState
    }
    try{
      setLoadingState(true);
      await postData(UPDATE_DEFAULT_BY_KEY, requestBody );
      await handleCategoryUpdateState( categoryState.text );
    } catch(error) {
      alert('Save Table Failed');
    } finally {
      setLoadingState(false);
    }
  }

  const handleDownload = async () => {
    const tableDataStateCopy = [...tableDataState];
    const tableHeaderStateCopy = [...tableHeaderState];
    try {
      handleDisplayTable();
      setTimeout(async () => {
        const table = tableRef.current;
        const canvas = await html2canvas(table);
        canvas.toBlob(function (blob) {
          saveAs(blob, `Roadmap-${categoryState}.png`);
          setTableDataState(tableDataStateCopy)
          setTableHeaderState(tableHeaderStateCopy)
        })
      }, 100);
    } catch (error) {
      console.error(error);
      setTableDataState(tableDataStateCopy)
      setTableHeaderState(tableHeaderStateCopy)
    }
  };

  const handleDisplayTable = () => {
    setTableDataState(
      tableDataState
      .filter(obj => obj.isDisplay === true)
      .map(({ isDisplay, ...remainingKey }) => remainingKey)
    )
    setTableHeaderState(
      tableHeaderState.filter(obj => obj.id !== 'isDisplay')
    )
  };

  const handleUpdateRoadMapState = (
    index: number,
    header: keyof RoadmapRow,
    value: string
  ) => {
    setTableDataState((prevState) => {
      if (index < 0 || index >= prevState.length) return prevState;
      const newTableDataState: Array<any> = [...prevState];
      newTableDataState[index][header] = value;
      return newTableDataState;
    });
  };

  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 mb-10">
        <OnehubDropdown
          showBorder={true}
          handleUpdateState={handleCategoryUpdateState}
          placeholder="Roadmap Category"
          value={categoryState.text}
          options={
            Object.values(ROADMAP_CATEGORIES).map(item => {
            return item.text; 
          })}          
        ></OnehubDropdown>
        { !loadingState &&        
          <span ref={tableRef} className="bg-lightPrimary dark:bg-navy-900 p-4">
            <h1 className="m-4 text-primary dark:text-white text-xl font-bold">{categoryState.text}</h1>
            <RoadmapTable
              headers={tableHeaderState}
              rows={tableDataState}
              handleUpdateState={handleUpdateRoadMapState}
            ></RoadmapTable>
          </span>
        }
        { loadingState && <div className="w-full h-full flex"><span className="mx-auto loading loading-dots loading-lg"/></div> }
        <div className="button__row flex justify-self-end gap-4">
          <button
            className="btn btn-secondary rounded-full w-32 shadow-xl"
            onClick={saveRoadmap}
          >
            <MdSave />
          </button>
          <button
            className="btn btn-primary rounded-full w-32 shadow-xl"
            onClick={handleDownload}
          >
            <MdFileDownload />
          </button>          
        </div>
      </div>
    </>
  );
};

export default Roadmap;
