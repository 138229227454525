export enum Legends {
    GREEN = "Green",
    AMBER = "Amber",
    RED = "Red",
}

export enum LegendsColorChart {
    Green = 'bg-green-300 dark:bg-green-900',
    Amber = 'bg-amber-300 dark:bg-amber-600',
    Red = 'bg-red-300 dark:bg-red-900',
}

export type DiagnosisType = {
    diagnosis: string,
    legends?: Legends
}

export const DiagnosisQuestions: Array<DiagnosisType> = [
    { diagnosis: "Employee engagement", },
    { diagnosis: "Vendor relationships", },
    { diagnosis: "Quality assurance", },
    { diagnosis: "Basic customer engagement practices", },
    { diagnosis: "Advanced customer engagement - customer delight", },
    { diagnosis: "Community awareness", },
    { diagnosis: "Community participation in business messaging", },
    { diagnosis: "Customer service - enablement and upselling", },
    { diagnosis: "Sales process", },
    { diagnosis: "Sales automation", },
    { diagnosis: "Marketing process", },
    { diagnosis: "Marketing automation", },
    { diagnosis: "Marketing strategy for digital enablement", },
    { diagnosis: "Defining performance and success metrics", },
    { diagnosis: "Product awareness across customers and vendors", },
    { diagnosis: "Product awareness with the community", },
]