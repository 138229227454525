import axios, { AxiosResponse } from 'axios';

export const fetchData = async (apiUrl: string, requestBody?: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(apiUrl, { data: requestBody });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const postData = async (apiUrl: string, requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.post(apiUrl, requestBody);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const deleteData = async (apiUrl: string, requestBody: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.delete(apiUrl, { data: requestBody });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

const handleAxiosError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    console.error('Response Error:', error.response.data);
    console.error('Status:', error.response.status);
    console.error('Headers:', error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Request Error:', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error:', error.message);
  }
  alert('Network Error');
  console.error('Config:', error.config);
};