import { useRef, useState } from 'react';
import OnehubDropdown from 'components/onehubDropdown';

enum Categories {
  PROJECT_DURATION = 'Project Duration',
  PROFILE = 'Profile',
}

type ProjectDurationsType = {
  text: string,
  duration: number
}

const Others = () => {
  const projectDurations: ProjectDurationsType[] = [
    { text: '3 months', duration: 3,},
    { text: '6 months', duration: 6,},
    { text: '9 months', duration: 9,},

  ]
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const datepickerRef = useRef(null);
  const [ category,  setCategory ] = useState('Project Duration')
  const [ projectStartDate,  setProjectStartDate ] = useState(new Date());
  const [ duration,  setDuration ] = useState(projectDurations[0].duration)
  const [ projectEndDate,  setProjectEndDate ] = useState(() => {
    const endDate = new Date(projectStartDate.getTime());
    endDate.setMonth(projectStartDate.getMonth() + duration);
    return endDate;
  });

  const options = ['Project Duration'];
  
  const handleDateChange = (selectedDate: string) => {
    let newDate = new Date(Date.parse(selectedDate));
    setProjectStartDate(newDate);
    getProjectEndDate(newDate, duration);
  }

  const handleDurationChange = (duration: number) => {
    setDuration(duration)
    getProjectEndDate(projectStartDate, duration);
  } 

  const getProjectEndDate = (projectStartDate?: Date, duration?: number) => {
    if(projectStartDate && duration) {
      const endDate = new Date(projectStartDate.getTime());
      endDate.setMonth(projectStartDate.getMonth() + duration);
      setProjectEndDate(endDate)
    }
  }
  // COMMENTED OUT FOR FUTURE DOWNLOAD IMAGE SUPPORT

  // const handleDownload = async (ref: React.MutableRefObject<any>) => {
  //   try {
  //     const form = ref.current;
  //     const canvas = await html2canvas(form);
  //     canvas.toBlob(function (blob) {
  //       saveAs(blob, `${category}.png`);
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <>
      <div className='mt-5 grid h-full grid-cols-1 gap-5 mb-10'>
        <OnehubDropdown
          showBorder={true}
          handleUpdateState={(selectedValue: string) => setCategory(selectedValue)}
          placeholder="Core Improvement Opportunities"
          value={category}
          options={options}
        ></OnehubDropdown>
        { category === Categories.PROJECT_DURATION &&
          <div className='max-w-[800px] items-end mt-4 flex flex-col gap-y-4 mb-10'>
            <div ref={datepickerRef} className='w-full flex gap-4 bg-lightPrimary dark:bg-navy-900'>
              <input 
                type="date" 
                placeholder="Select projectStartDate" 
                className="input input-bordered w-full max-w-xs grow" 
                value={projectStartDate.toISOString().split('T')[0]}
                onChange={(event) => handleDateChange(event.target.value)}
              />
              <select 
                className="select select-bordered w-full max-w-xs grow"
                onChange={(event) => handleDurationChange(Number(event.target.value))}
                value={duration}
              >
                <option disabled>Project Duration</option>
                {
                  projectDurations.map((duration: ProjectDurationsType) => (
                    <option key={duration.duration} value={duration.duration}>{duration.text}</option>
                  ))
                }                
              </select>
            </div>
            <div className="w-full flex card bg-base-100 rounded-lg">
              <div className="flex-1 card-body">
                <strong>Proposed Finished Project Date: <br/></strong>
                {months[projectEndDate.getMonth()]} {projectEndDate.getDate()}, {projectEndDate.getFullYear()}
              </div>
            </div>           
          </div>
        }
      </div>
    </>    
  );
};

export default Others;
