import {
  GapAnalysisHeader,
  GapAnalysisOptionColumns,
  BUSINESS_PURPOSE,
  GapAnalysisInputs,
  gapAnalysisOrder,
} from "./variables/tableDataColumns";

import { 
  MdFileDownload,
  MdSave 
} from "react-icons/md";

import GapAnalysisTable from "./components/GapAnalysisTable";

import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import saveAs from "file-saver";
import { postData } from "services/apiServices";
import { GET_DEFAULT_BY_KEY, UPDATE_DEFAULT_BY_KEY } from "data/apiUrls";
import OnehubDropdown from "components/onehubDropdown";
const GapAnalysis = () => {
  useEffect(() => {
    getGapAnalysisTableContentByKey( BUSINESS_PURPOSE.ORDER_MANAGEMENT.key );
  }, []);
  
  const tableRef = useRef(null);
  const [tableDataState, setTableDataState] = useState([]);
  const [tableOptionState, setTableOptionState] = useState([]);
  const [tableHeaderState, setTableHeaderState] = useState(GapAnalysisHeader);
  const [businessPurposeState, setBusinessPurposeState] = useState(BUSINESS_PURPOSE.ORDER_MANAGEMENT);
  const [loadingState, setLoadingState] = useState(false);
  const [/*errorState*/, setErrorState] = useState(false);
  const profile = JSON.parse(localStorage.getItem("profiles"))[0];
  const getGapAnalysisTableContentByKey = async (key: string) => {
    try {
      // Show loading state
      setLoadingState(true);
      const response = await postData(GET_DEFAULT_BY_KEY, { key: key, profile: profile })
      const defaultData = sortTableDataStateByOrder(response[0][key], gapAnalysisOrder);
      setTableDataState(getDefaultTableFromOptions(defaultData));
      setTableOptionState(defaultData);
      return defaultData;
    } catch (error) {
      setErrorState(true)
      alert('Connection Error');
      console.error(error);
    } finally {
      // Hide loading
      setLoadingState(false);
    }   
  }

  // // Function to generate default table data from options
  const getDefaultTableFromOptions = (OptionTable: GapAnalysisOptionColumns[]): GapAnalysisInputs[] => {
    return OptionTable.map((row) => {
      let inputs: GapAnalysisInputs = {};
        for (const [key, value] of Object.entries(row)) {
          if(key === "isDisplay") inputs[key as keyof GapAnalysisInputs] = 'true'; 
          if (value.length > 0 && inputs !== undefined) {  
            if (Array.isArray(value)) inputs[key as keyof GapAnalysisInputs]  = value[0];
          }
        }
        return inputs;
    });
  }  
  
  const handleBusinessPurposeUpdateState = (businesPurpose: string) => {
    const selectedBusinessPurpose = Object.values(BUSINESS_PURPOSE).find(o => o.text === businesPurpose);
    setBusinessPurposeState(selectedBusinessPurpose)
    getGapAnalysisTableContentByKey(selectedBusinessPurpose.key);
  };

  const sortTableDataStateByOrder = (objs: any, order: string[]) => {
    return objs.map((obj: { [x: string]: any; }) => {
      return Object.keys(obj)
        .sort((a, b) => { return order.indexOf(a) - order.indexOf(b)})
        .reduce((result: any, key) => {
          result[key] = obj[key];
          return result;
        }, {});
  
    });      
  }

  const handleUpdateState = (
    index: number,
    header: keyof GapAnalysisOptionColumns,
    value: string,
    valueIndex?: number,
  ) => {
    setTableDataState((prevState) => {
      if (index < 0 || index >= prevState.length) return prevState;
      const newTableDataState: Array<any> = [...prevState];
      newTableDataState[index][header] = value.toString();
      return newTableDataState;
    });
    const newTableOptionState = [...tableOptionState];
    newTableOptionState[index][header][valueIndex] = value;
    setTableOptionState(newTableOptionState);
  };

  const handleDisplayTable = () => {
    setTableDataState(
      tableDataState
        .filter(obj => obj.isDisplay === 'true')
        .map(({ isDisplay, ...remainingKey }) => remainingKey)
    )
    setTableHeaderState(
      tableHeaderState.filter(obj => obj.id !== 'isDisplay')
    )
  }


  const saveTableState = async () => {
    const requestBody = {
      partitionKey: businessPurposeState.key,
      [businessPurposeState.key]: tableOptionState
    }
    try{
      setLoadingState(true);
      await postData(UPDATE_DEFAULT_BY_KEY, requestBody );
      await getGapAnalysisTableContentByKey( requestBody.partitionKey );
    } catch(error) {
      setErrorState(true);
      alert('Save Table Failed');
    } finally {
      setLoadingState(false);
    }
  }

  const handleDownload = async () => {
    const tableDataStateCopy = [...tableDataState];
    const tableHeaderStateCopy = [...tableHeaderState];
    try {
      handleDisplayTable();
      setTimeout(async () => {
        const table = tableRef.current;
        const canvas = await html2canvas(table);
        canvas.toBlob(function (blob) {
          saveAs(blob, `GAP-Analysis-${businessPurposeState}.png`);
          setTableDataState(tableDataStateCopy)
          setTableHeaderState(tableHeaderStateCopy)
        })
      }, 100);
    } catch (error) {
      console.error(error);
      setTableDataState(tableDataStateCopy)
      setTableHeaderState(tableHeaderStateCopy)
    }
  };
  
  return (
    <>
      <div className="mt-5 grid h-full grid-cols-1 gap-5">
        <OnehubDropdown
          showBorder={true}
          handleUpdateState={handleBusinessPurposeUpdateState}
          placeholder="Core Improvement Opportunities"
          value={businessPurposeState.text}
          options={
            Object.values(BUSINESS_PURPOSE).map(item => {
            return item.text; 
          })}
        ></OnehubDropdown>
        {/* {
          errorState && <div className="alert alert-error">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>Error! Action Failed.</span>
          </div>
        } */}
        <span ref={tableRef} className="bg-lightPrimary dark:bg-navy-900 p-4">
          <div className="h-[calc(100%+8rem)]">
            <h1 className="m-4 text-primary dark:text-white text-xl">
              <strong>Business Purpose: </strong> 
              {businessPurposeState.text}
            </h1>
            { !loadingState && 
              <GapAnalysisTable
                handleUpdateState={handleUpdateState}
                tableData={tableDataState}
                tableOptions={tableOptionState}
                tableHeader={tableHeaderState}
              /> 
            }
            { loadingState && <div className="w-full h-full flex"><span className="mx-auto loading loading-dots loading-lg"/></div> }
            
          </div>
        </span>
        <div className="button__row flex justify-self-end gap-4">
          <button
            className="btn btn-secondary rounded-full w-32 shadow-xl"
            onClick={saveTableState}
          >
            <MdSave />
          </button>
          <button
            className="btn btn-primary rounded-full w-32 shadow-xl"
            onClick={handleDownload}
          >
            <MdFileDownload />
          </button>          
        </div>

      </div>
    </>
  );
};

export default GapAnalysis;
