export const API_URL = {
    getOnehubDefaultByKey: "/getOnehubDefaultByKey",
}
// to be included in .env
// ===== prod =====
export const GET_DEFAULT_BY_KEY = 'https://m4tf4hrj2h.execute-api.us-east-2.amazonaws.com/default/getOnehubDefaultByKey';
export const UPDATE_DEFAULT_BY_KEY = 'https://rso69r9961.execute-api.us-east-2.amazonaws.com/default/updateOnehubDefaultByKey';
export const UPDATE_PROFILE = 'https://zyg15o7s71.execute-api.us-east-2.amazonaws.com/default/updateProfile';
export const DELETE_PROFILE = 'https://7bzirvrmle.execute-api.us-east-2.amazonaws.com/default/deleteProfile';
export const GET_PROFILES = 'https://2mtzcpl1w0.execute-api.us-east-2.amazonaws.com/default/getProfiles';

// ===== dev =====
// export const GET_DEFAULT_BY_KEY = 'https://r6isl2pxxc.execute-api.us-east-2.amazonaws.com/default/getOnehubDefaultByKey-dev';
// export const UPDATE_DEFAULT_BY_KEY = 'https://hyxspkj77d.execute-api.us-east-2.amazonaws.com/default/updateOnehubDefaultByKey-dev';
// export const UPDATE_PROFILE = 'https://62iyasrj5c.execute-api.us-east-2.amazonaws.com/default/updateProfile-dev';
// export const DELETE_PROFILE = 'https://8xrleh5zt7.execute-api.us-east-2.amazonaws.com/default/deleteProfile-dev';
// export const GET_PROFILES = 'https://hgeg7eoaj3.execute-api.us-east-2.amazonaws.com/default/getProfiles-dev';