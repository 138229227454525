import { useEffect, useRef, useState } from "react";

type DropdownProps = {
  handleUpdateState?: Function | any;
  value?: string;
  placeholder?: string;
  options?: Array<any>;
  showBorder?: boolean;
  isEditable?: boolean;
  fontSize?: string; //tailwind css class
  label?: string;
};

const OnehubDropdown = (props: DropdownProps) => {
  let {
    handleUpdateState,
    label,
    options = [],
    placeholder = "",
    value = "",
    showBorder = false,
    isEditable = false,
    fontSize = "text-base", //tailwind-css class
  } = props;
  const selectRef = useRef<HTMLUListElement>(null);
  const textareaRef = useRef<HTMLDivElement>(null);
  const [selectIndexState, setSelectIndexState] = useState(0);
  useEffect(() => {
    if (value) {
      textareaRef.current.textContent = value;
    }
  }, [value]);

  const handleTextareaInput = () => {
    if (isEditable && handleUpdateState) {
      handleUpdateState(textareaRef.current.textContent, selectIndexState);
    }
  };
  const hideDropdown = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };
  const handleOptionSelect = (selectedValue: string, index: number) => {
    hideDropdown();
    if (handleUpdateState) {
      setSelectIndexState(index);
      handleUpdateState(selectedValue, index);
    } else {
      console.warn("handleUpdateState function missing");
    }
  };
  return (
    <>
      <div className="dropdown w-full">
        {label && <label className="label">
          <span className="label-text">{label}</span>
        </label>}
        <p
          tabIndex={0}
          contentEditable={isEditable}
          suppressContentEditableWarning={isEditable}
          ref={textareaRef}
          className={`
          ${
            showBorder
              ? "select-bordered select flex w-full max-w-xs items-center text-primary dark:text-white bg-white dark:bg-primary"
              : "dark:text-white p-0  text-primary outline-0 flex justify-center"
          } ${fontSize}`}
          onBlur={handleTextareaInput}
        >
          {value.length > 0 ? value : placeholder}
        </p>
        {
          options.length > 0 && 
          <ul
            ref={selectRef}
            tabIndex={0}
            // className="dropdown-content menu z-[99] rounded-md bg-base-100 p-2 shadow"
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box"
          >
            {options.map((option, i) => (
              <li className="max-w-[250px] w-full p-1 whitespace-nowrap overflow-hidden text-ellipsis" key={i} onClick={() => handleOptionSelect(option, i)}>
                <p className="p-1 whitespace-nowrap overflow-hidden text-ellipsis">{option}</p>
              </li>
            ))}
          </ul>
        }
      </div>
    </>
  );
};

export default OnehubDropdown;
