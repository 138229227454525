import React, { useEffect, useRef, useState } from "react";
import { DiagnosisType, Legends, LegendsColorChart } from "../variables/diagnosisQuestions"
import { GET_DEFAULT_BY_KEY, UPDATE_DEFAULT_BY_KEY } from "data/apiUrls";
import { postData } from "services/apiServices";
import { MdFileDownload, MdSave } from "react-icons/md";
export default function DiagnosisForm(props: {handleDownload: Function}) {
  const { handleDownload } = props;
  const [diagnosis, setDiagnoses] = useState<DiagnosisType[]>([]);
  const [diagnosisPartitionKey, setDiagnosisPartitionKey] = useState<string>('');
  const [loading, setLoading] = useState<Boolean>(false);
  const formRef = useRef(null);
  const API_KEY = 'diagnosisForm';
  const profile = JSON.parse(localStorage.getItem("profiles"))[0];
  useEffect(() => {
    getDiagnosisQuestions();
  }, []);

  const getDiagnosisQuestions = async () => {
    try {
      setLoading(true);
      const response = await postData(GET_DEFAULT_BY_KEY, { key: API_KEY, profile: profile })
      setDiagnosisPartitionKey(response[0]['partitionKey'])
      setDiagnoses(response[0][API_KEY]);
    } catch (error) {
      console.error(error);
      alert('get data from backend failed');
    } finally {
      setLoading(false);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setDiagnoses((prevDiagnoses) =>
      prevDiagnoses.map((diagnosis, i) =>
        i === index ? { ...diagnosis, [name]: name === 'legends' ? (value as Legends) : value } : diagnosis
      )
    );
  };

  const handleSaveDiagnosisQuestion = async () => {
    try{
      const requestBody = {
        partitionKey: diagnosisPartitionKey,
        type: API_KEY,
        [API_KEY]: diagnosis.map((item) => ({ diagnosis: item.diagnosis }))
      }
      setLoading(true);      
      await postData(UPDATE_DEFAULT_BY_KEY, requestBody );
    } catch(error) {
      console.error(error)
      alert('Save Data Failed');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      { !loading && 
        <div className='max-w-[600px] items-end mt-4 flex flex-col gap-y-4 mb-10'>
          <div ref={formRef} className='w-full bg-lightPrimary dark:bg-navy-900 p-4'>
            <div className="card bg-base-100 dark:bg-navy-700 px-6 py-6 gap-y-1.5 sm:overflow-x-auto">
              {diagnosis.map((_: DiagnosisType, index: number) => (
                <div className="grid grid-cols-12" key={index}>
                  <input
                    className="input input-ghost col-span-10 min-h-0 h-8 dark:text-white"
                    type="text"
                    id={`diagnosisInput-${index}`}
                    name="diagnosis"
                    value={_.diagnosis}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                  <select
                    className={
                      `select select-ghost col-span-2 rounded-sm min-h-0 h-8 bg-none pr-4 justify-self-end text-center dark:text-white
                      ${LegendsColorChart[_.legends]}
                      `
                    }
                    
                    id={`legendsInput-${index}`}
                    name="legends"
                    value={_.legends || ''}
                    onChange={(event) => handleInputChange(event, index)}
                  >
                    <option value="">Legend</option>
                    {Object.values(Legends).map((legend) => (
                      <option key={legend} value={legend}>
                        {legend}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full justify-end gap-x-4">
            <button className='btn btn-secondary rounded-full w-32 shadow-xl' onClick={handleSaveDiagnosisQuestion}> <MdSave /> </button>
            <button className='btn btn-primary rounded-full w-32 shadow-xl' onClick={() => handleDownload(formRef)}> <MdFileDownload /> </button>
          </div>
        </div>        
      }
      { loading && <div className="w-full h-full flex"><span className="mx-auto loading loading-dots loading-lg"/></div> }
    </>
    
  );
}
