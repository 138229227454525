import { Legends, LegendsColorChart } from "../variables/diagnosisQuestions"
export default function LegendChart() {
  return (
    <>
      <h1 className="px-6 py-4"><strong>Legend</strong></h1>
      <div className="card bg-base-100 px-6 py-6 gap-y-1.5 sm:overflow-x-auto">
        <div className="flex justify-between">
          <div>Good operating health</div>
          <div className={`p-1 w-20 text-center font-bold text-sm rounded-sm ${LegendsColorChart.Green}`}>{Legends.GREEN}</div>
        </div>
        <div className="flex justify-between">
          <div>Fair operating health</div>
          <div className={`p-1 w-20 text-center font-bold text-sm rounded-sm ${LegendsColorChart.Amber}`}>{Legends.AMBER}</div>
        </div>
        <div className="flex justify-between">
          <div>Needs Improvement</div>
          <div className={`p-1 w-20 text-center font-bold text-sm rounded-sm ${LegendsColorChart.Red}`}>{Legends.RED}</div>
        </div>
      </div>
    </>
  );
}
