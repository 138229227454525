import { RoadmapHeader, RoadmapRow } from "./dataType"

// GapAnalysisHeader
export const roadmapWeeksHeaders: RoadmapHeader[] = [
    {
      id: "isDisplay",
      header: "",
    },
    {
      id: "steps",
      header: "",
    },
    {
      id: "week1",
      header: "W1",
    },
    {
      id: "week2",
      header: "W2",
    },
    {
      id: "week3",
      header: "W3",
    },
    {
        id: "week4",
        header: "W4",
    },
    {
        id: "week5",
        header: "W5",
    },  
    {
        id: "week6",
        header: "W6",
    },  
    {
        id: "week7",
        header: "W7",
    },  
    {
        id: "week8",
        header: "W8",
    },  
    {
        id: "week9",
        header: "W9",
    },  
    {
        id: "week10",
        header: "W10",
    },
    {
        id: "week11",
        header: "W11",
    },
    {
        id: "week12",
        header: "W12",
    },   
    {
        id: "week13",
        header: "W13",
    },           
]

export const roadmapDaysHeaders = [
  {
    id: "isDisplay",
    header: "",
  },  
  {
    id: "steps",
    header: "",
  },       
  {
    id: "firstMonday",
    header: "Mon",
  },       
  {
    id: "firstTuesday",
    header: "Tue",
  },       
  {
    id: "firstWednesday",
    header: "Wed",
  },       
  {
    id: "firstThursday",
    header: "Thu",
  },       
  {
    id: "firstFriday",
    header: "Fri",
  },       
  {
    id: "weekends",
    header: "",
  },
  {
    id: "secondMonday",
    header: "Mon",
  },       
  {
    id: "secondTuesday",
    header: "Tue",
  },       
  {
    id: "secondWednesday",
    header: "Wed",
  },       
  {
    id: "secondThursday",
    header: "Thu",
  },       
  {
    id: "secondFriday",
    header: "Fri",
  },      
]

export const ROADMAP_CATEGORIES = {
	"SALES": {
    text: "Sales",
    key: "salesRoadmap",
  },
	"MARKETING": {
    text: "Marketing",
    key: "marketingRoadmap",
  },
	"WEEK_3": {
    text: "Weeks 3 (in detail)",
    key: "week3Roadmap",
  },
}


export const salesRoadmap: RoadmapRow[] = [
  { isDisplay: true,
    steps: "Defining the organizational objectives of new revenue opportunities",
    week1: true,
  },
  { isDisplay: true,
    steps: "Digitizing and documenting all sales activities from their current channel",
    week2: true,
  },
  { isDisplay: true,
    steps: "Getting approval from key stakeholders for the new digitized Sales process",
    week3: true,
  },
  { isDisplay: true,
    steps: "Hire a person to execute the new Sales Process",
    week4: true,
  },
  { isDisplay: true,
    steps: "Evaluating the new CRM solutions that fit the new digitized Sales Process",
    week1: true,
  },
  { isDisplay: true,
    steps: "Identify business cases and improvements to the sales automation software",
    week1: true,
  },
  { isDisplay: true,
    steps: "Demo and tutoring the key personnel to use the CRM",
    week3: true,
    week4: true,
  },
  { isDisplay: true,
    steps: "Ensuring the software methods and processes have been understood by all users of the software",
    week4: true,
    week5: true,
  },
  { isDisplay: true,
    steps: "Ensure sales personnel use the designated software, make entries, track records and fulfill software obligations",
    week2: true,
    week3: true,
    week4: true,
  },
  { isDisplay: true,
    steps: "Continuously monitor data entry and processing in the identified software solution to ensure data continuity and business continuity",
    week1: true,
    week2: true,
  },
  { isDisplay: true,
    steps: "Periodically check for KPIs being met and all stakeholders are engaged in sales activity",
    week4: true,
  },
  { isDisplay: true,
    steps: "Check for performance indicators/dashboards and insights that indicate progress and performance",
    week5: true,
    week6: true,
    week7: true,
    week8: true,
    week9: true,
  },
  { isDisplay: true,
    steps: "Review sales performance with the owner and/or senior leadership team",
    week7: true,
    week8: true,
    week9: true,
    week10: true,
    week11: true,
  },
  { isDisplay: true,
    steps: "Continue to look for associates and partners in retail sales",
    week10: true,
    week11: true,
  },
  { isDisplay: true,
    steps: "Engage in meaningful discussions with the Sales person, improve communication between them and the management",
    week10: true,
    week11: true,
    week12: true,
    week13: true,
  },
  { isDisplay: true,
    steps: "Once the sales person is fully occupied, add new staff members and onboard them",
    week10: true,
    week11: true,
    week12: true,
    week13: true,
  },
  { isDisplay: true,
    steps: "Repeat the process to match and scale the Sales team to match capabilities",
    week11: true,
    week12: true,
    week13: true,
  },
]

export const marketingRoadmap:RoadmapRow[] = [
    { isDisplay: true, 
      steps: "Define a high level Marketing Objective",
      week1: true,
    },
    { isDisplay: true, 
      steps: "Define a Marketing Strategy",
      week1: true,
    },
    { isDisplay: true, 
      steps: "Digitizing the Marketing Process",
      week3: true,
      week4: true,
    },
    { isDisplay: true, 
      steps: "Define a Digital Marketing Strategy & KPIs",
      week3: true,
      week4: true,
    },
    { isDisplay: true, 
      steps: "Getting approval from the owner for the new digitized Marketing process",
      week3: true,
      week4: true,
    },
    { isDisplay: true, 
      steps: "Identifying employees to execute the new Marketing Process",
      week4: true,
      week5: true,
    },
    { isDisplay: true, 
      steps: "Building/identifying the new software solutions that fit the new digitized Marketing Process",
      week3: true,
      week4: true,
      week5: true,
    },
    { isDisplay: true, 
      steps: "Exploring competitive options in digital tech and software to achieve the desired objectives.",
      week1: true,
      week2: true,
    },
    { isDisplay: true, 
      steps: "Finalize the right software solution",
      week4: true,
    },
    { isDisplay: true, 
      steps: "Implementing the software solution by purchase/ SaaS subscription",
      week5: true,
      week6: true,
      week7: true,
      week8: true,
    },
    { isDisplay: true, 
      steps: "Demo and tutoring the employees to use the software",
      week7: true,
      week8: true,
      week9: true,
      week10: true,
      week11: true,
    },
    { isDisplay: true, 
      steps: "Ensuring the software methods and processes have been understood by all users of the software down the line in the organization",
      week7: true,
      week8: true,
      week9: true,
      week10: true,
    },
    { isDisplay: true, 
      steps: "Ensure all employees use the designated software",
      week10: true,
      week11: true,
    },
    { isDisplay: true, 
      steps: "Periodically check to ensure employee performance in marketing",
      week10: true,
      week11: true,
      week12: true,
    },
    { isDisplay: true, 
      steps: "Check for performance indicators/dashboards and insights that indicate progress and performance",
      week11: true,
      week12: true,
    },
    { isDisplay: true, 
      steps: "Review KPIs and data points with owner/senior leadership",
      week11: true,
      week12: true,
    },
    { isDisplay: true, 
      steps: "Build an adaptive learning process that showcases improvement and provisions for corrective actions in the Marketing process",
      week11: true,
      week12: true,
    },
    { isDisplay: true, 
      steps: "Continue to look for high impact changes that could bring about significant marketing improvements",
      week11: true,
      week12: true,
      week13: true,
    },
    { isDisplay: true, 
      steps: "Engage in meaningful discussions with all Marketing performers and improve their participation",
      week13: true,
    },
    { isDisplay: true, 
      steps: "Once the marketing person is fully occupied, add new staff members and onboard them",
      week13: true,
    },
    { isDisplay: true, 
      steps: "Repeat the process to match and scale the Marketing team to match capabilities  ",
      week13: true,
    },
]

export const week3Roadmap:RoadmapRow[] = [
    { isDisplay: true, 
      steps: "Identify key data sources to feed steps into",
      firstMonday: true,
      firstTuesday: true,
    },
    { isDisplay: true, 
      steps: "Creating an online cloud storage solution for a data repository",
      firstWednesday: true,
    },
    { isDisplay: true, 
      steps: "Create steps categorization maps",
      firstThursday: true,
    },
    { isDisplay: true, 
      steps: "Sort data into lead time and real-time data",
      firstFriday: true,
    },
    { isDisplay: true, 
      steps: "Identify key external engagement channels",
      secondMonday: true,
      secondTuesday: true,
      secondWednesday: true,      
    },
    { isDisplay: true, 
      steps: "Identify key interface engagement channels",
      secondMonday: true,
      secondTuesday: true,
      secondWednesday: true,
    },
    { isDisplay: true, 
      steps: "Identify key internal engagement channels",
      secondMonday: true,
      secondTuesday: true,
      secondWednesday: true,      
    },
    { isDisplay: true, 
      steps: "Build key funnel qualifiers - define different stages",
      secondMonday: true,
      secondTuesday: true,
      secondWednesday: true,      
    },
    { isDisplay: true, 
      steps: "Define outcomes",
      secondWednesday: true,
      secondThursday: true,
      secondFriday: true,      
    },
    { isDisplay: true, 
      steps: "Implement plan. For a small sample size of data, execute for the same",
    },
    { isDisplay: true, 
      steps: "Scale data and repository plans and execute in scale and loops",
      
    },
    { isDisplay: true, 
      steps: "Supply data for 1 month of digital marketing campaigns",
      firstMonday: true,
      firstTuesday: true,
      firstWednesday: true,
      firstThursday: true,
      firstFriday: true,      
      secondMonday: true,
      secondTuesday: true,
      secondWednesday: true,
      secondThursday: true,
      secondFriday: true,         
    },
]

export enum RoadmapColorChart {
  week1 = "bg-green-900",
  week2 = "bg-green-800",
  week3 = "bg-green-600",
  week4 = "bg-green-500",
  week5 = "bg-green-400",
  week6 = "bg-green-300",
  week7 = "bg-amber-700",
  week8 = "bg-amber-600",
  week9 = "bg-amber-500",
  week10 = "bg-amber-400",
  week11 = "bg-amber-300",
  week12 = "bg-amber-200",
  week13 = "bg-gray-500",
  firstMonday = "bg-navy-700",
  firstTuesday = "bg-navy-500",
  firstWednesday = "bg-navy-300",
  firstThursday = "bg-amber-400",
  firstFriday = "bg-green-400",
  weekends = "",
  secondMonday = "bg-amber-400",
  secondTuesday = "bg-amber-500",
  secondWednesday = "bg-amber-600",
  secondThursday = "bg-red-400",
  secondFriday = "bg-red-600",
}

export const RoadmapKeyOrder = [
  'isDisplay',
  'steps',
  'week1',
  'week2',
  'week3',
  'week4',
  'week5',
  'week6',
  'week7',
  'week8',
  'week9',
  'week10',
  'week11',
  'week12',
  'week13',
  'firstMonday',
  'firstTuesday',
  'firstWednesday',
  'firstThursday',
  'firstFriday',
  'weekends',
  'secondMonday',
  'secondTuesday',
  'secondWednesday',
  'secondThursday',
  'secondFriday',
]