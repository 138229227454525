import ProfilesComponent from './components/profile'

const Profiles = () => {
  return (
    <>
      <div className='mt-5 grid h-full grid-cols-1 gap-5 mb-10'>
          <ProfilesComponent/>
      </div>
    </>    
  );
};

export default Profiles;
