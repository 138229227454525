// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
export default function SignIn() {
  let [password, setPassword] = useState<string>('');
  let [error, setError] = useState<boolean>(false);
  let [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const SIGNIN_PASSWORD = 'onehubD3s!gn';
  const navigate = useNavigate();
  const signIn = () => {
    console.log(`signIn with password: ${password}`);
    if(password === SIGNIN_PASSWORD) {
      // Redirect on valid password
      setIsLoggedIn(true);
      // save isLoggedIn state to local storage with expiration date of 30 days
      saveLoggedInStateToLocalStorage();
      
      navigate('/report-manager/diagnosis-report', { replace: true });
    } else {
      // Show error for invalid password
      setError(true);
  
    }
  
  };

  const saveLoggedInStateToLocalStorage = () => {
    // Save to localStorage
    const expiresIn = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000); 
    localStorage.setItem('isLoggedIn', JSON.stringify({
      value: true,
      expiry: expiresIn.getTime()
    }));    
  }
  
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div> */}
        {/* Password */}
        {/* <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Password"
          id="password"
          type="password"
        /> */}
        <div className="form-control w-full">
          <label className="label"><span className="label-text">Password</span></label>
          <input type="password" placeholder="Enter password here..." 
            className="input input-bordered w-full" 
            onChange={(event) => {setPassword(event.target.value)}}
          />
          { error && 
            <label className="label">
              <span className="label-text-alt text-red-600">Incorrect Password, Please enter again</span>
            </label>      
          }
        </div>


        {/* Checkbox */}
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}
        <button className="mt-8 btn btn-primary w-full" onClick={() => signIn()}>
          Sign In
        </button>
      </div>
    </div>
  );
}
