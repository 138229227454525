import { GapAnalysisInputs, GapAnalysisOptionColumns } from "../variables/tableDataColumns";
import OnehubDropdown from "components/onehubDropdown";
import { useMemo } from "react";

const GapAnalysisTable = (props: {
  handleUpdateState: Function;
  tableData: Array<GapAnalysisInputs>;
  tableHeader: Array<{ id: keyof GapAnalysisOptionColumns; header: string }>;
  tableOptions: Array<GapAnalysisOptionColumns>
}) => {
  const { handleUpdateState, tableData, tableHeader, tableOptions } = props;

  const headers = useMemo(() => {
    return tableHeader.map((head: { id: any; header: string }, index) => {
      let classNames = 'text-white text-sm bg-primary';
      if (index === 0) {
        classNames += ' rounded-tl-lg pl-4';
      } else if (index === tableHeader.length - 1) {
        classNames += ' rounded-tr-lg pr-4';
      } else {
        classNames += ' px-1';
      }
  
      return (
        <th className={classNames} key={index}>
          {head.header}
        </th>
      );
    });
  }, [tableHeader]);

  const columns = useMemo(() => {
    const handleUpdateSelectionState = (
      rowIndex: number,
      header: string,
      value: string,
      valueIndex: number
    ) => {
      handleUpdateState(rowIndex, header, value, valueIndex);
    };

    const handleCheckboxChange = (
      rowIndex: number,
      header: string,
      event: React.ChangeEvent<HTMLInputElement>) => {
      handleUpdateState(rowIndex, header, event.target.checked);
    };
    if(Array.isArray(tableData)) {
      return tableData.map((row, rowIndex) => {
        return (
          <tr key={rowIndex} className="even:bg-secondary-content even:dark:bg-gray-800 ">
            {Object.entries(row).map(([key, value]) => {
              const matchingHeader = tableHeader.find((header) => header.id === key);
              if (matchingHeader && Array.isArray(tableOptions[rowIndex][matchingHeader.id])) {
                return (
                  <td className="px-1" key={`${rowIndex}-${key}`}>
                    <OnehubDropdown
                      handleUpdateState={(updatedState: string, updatedIndex: number) =>
                        handleUpdateSelectionState(
                          rowIndex,
                          matchingHeader.id,
                          updatedState,
                          updatedIndex,
                        )
                      }
                      placeholder="Input here..."
                      isEditable={true}
                      options={tableOptions[rowIndex][matchingHeader.id] as Array<string>}
                      value={value}
                      fontSize={matchingHeader.id === "priority" ? "text-4xl" : "text-base"}
                    />
                  </td>
                );
              } else {
                if(value === 'true' || value === 'false') {
                  return (
                    <td className="" key={`${rowIndex}-${key}`}>
                      <input 
                        type="checkbox" 
                        checked={value === 'true'} 
                        className="checkbox checkbox-secondary" 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(rowIndex, matchingHeader.id, event)}
                      />
                    </td>
                  )
                } else {
                  return null;
                }
              }
            })}
          </tr>
        );
      });
    }
  }, [tableData, tableHeader, tableOptions, handleUpdateState]);

  return (
    <div className="h-[calc(100%+8rem)] overflow-x-auto">
      <table className="table bg-white dark:bg-navy-700">
        <thead className="color: white dark:bg-navy-700">
          <tr className="bg-primary rounded-t-lg" key="body">{headers}</tr>
        </thead>
        <tbody>{columns}</tbody>
      </table>
    </div>
  );
};

export default GapAnalysisTable;
