import { DELETE_PROFILE, GET_PROFILES, UPDATE_PROFILE } from 'data/apiUrls';
import React, { useEffect, useState } from 'react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { deleteData, fetchData, postData } from 'services/apiServices';

const ProfilesComponent = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [newProfile, setNewProfile] = useState('');
  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    handleGetProfiles();
  }, []);
  
  const handleGetProfiles = async () => {
    try {
      const response = await fetchData(GET_PROFILES);
      if (Array.isArray(response)) {
        setProfiles(response);

      } else {
        setProfiles([]);
      }
    } catch (error) {
      // Handle the error here or rethrow it to propagate to the caller
      console.error('Error:', error);
      throw error;
    }
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked((prevState) => !prevState);
  };
  const defaultItems = [
    "marketingOptions",
    "marketingRoadmap",
    "week3Roadmap",
    "vendorManagementAndQualification",
    "diagnosisForm",
    "orderManagementOptions",
    "salesRoadmap",
    "projectManagementOptions",
    "salesProcessingAndMethodsOptions"
  ]
  const handleAddProfile = async () => {
    
    try{
      const requestBody = {
        profile: newProfile,
        defaultItems: defaultItems
      }
      await postData(UPDATE_PROFILE, requestBody );
      setProfiles((prevProfiles) => [...prevProfiles, newProfile]);
    } catch(error) {
      console.error(error)
      alert('Save Data Failed');
    }
    setNewProfile('');
    updateLocalStorageProfiles();
  };

  // const handleSaveDiagnosisQuestion = async () => {

  // }

  const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setNewProfile(event.target.value);
  };

  const handleDeleteProfile = async (profile: string, index: number) => {
    const requestBody = {
      profile: profile
    }
    const res = await deleteData(DELETE_PROFILE, requestBody);
    console.log(res)
    const updatedProfiles = [...profiles];
    updatedProfiles.splice(index, 1);
    setProfiles(updatedProfiles);
    updateLocalStorageProfiles();
  };

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      handleAddProfile();
    }    
  }

  const updateLocalStorageProfiles = () => {
    localStorage.setItem("profiles", JSON.stringify(profiles));
  }

  return (
    <div>
      <p className="m-4 text-lg font-bold">Profile Management</p>
      {profiles.map((profile, index) => (
        <div key={index}>
          <div className="card m-4 h-20 w-96 bg-base-100 shadow-xl">
            <div className="card-body flex flex-row justify-between p-4">
              <h4 className="card-title">{profile}</h4>
              <div className="card-actions justify-end">
                <button
                  className="btn-circle btn"
                  onClick={() => handleDeleteProfile(profile, index)}
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="card collapse m-4 w-96 bg-base-100 shadow-xl">
        <input
          type="checkbox"
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange}
        />
        <div className="collapse-title flex min-h-[5rem] items-center justify-between pr-4 text-xl font-bold">
          <p>Add Profile</p>
        </div>
        <div className="collapse-content">
          <div className="flex gap-2">
            <input
              type="text"
              placeholder="Profile Name"
              className="input-bordered input w-full max-w-xs"
              value={newProfile}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <button className="btn-outline btn-circle btn" onClick={handleAddProfile}>
              <FaPlus />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilesComponent;